<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Market</div>
        <!--<router-link :to="{path: `/market/membership`}">
          <span class="winex-btn primary-btn minting-btn">Membership Minting</span>
        </router-link>-->
      </div>
    </div>
    <div class="winex-wine-list-wrap">
      <div class="filter-wrap pc-filter-wrap">
        <!-- TODO :: 잠시 예외 처리 -->
        <div class="filter-title-wrap">
          <h2 class="title">Filter</h2>
          <div class="filter" @click="getMembershipFilterList"></div>
        </div>
        <PcGroupCheckbox
          v-for="item in filterList"
          :key="item.name"
          :list="item.list"
          :title="item.name"
          :isOpen="item.isOpen"
          :change-filter="changeFilter"
        />
      </div>
      <div class="wine-list-wrap">
        <div class="pc-list-wrap">
          <div class="order-wrap">
            <ul class="order-list">
              <li><label class="order-radio"><input type="radio" name="order" v-model="nftOrder" value="1" @click="changeOrder(1)"><span>최신순</span></label></li>
              <li><label class="order-radio"><input type="radio" name="order" v-model="nftOrder" value="2" @click="changeOrder(2)"><span>가격 높은 순</span></label></li>
              <li><label class="order-radio"><input type="radio" name="order" v-model="nftOrder" value="3" @click="changeOrder(3)"><span>가격 낮은 순</span></label></li>
            </ul>
          </div>
          <PcMembershipList
          :membership-n-f-t-list="viewMembershipList"
          :move-page="clickPager"
          :page-info="pageInfo"
          :no-result-text="noResultText"></PcMembershipList>
        </div>
        <div class="mobile-list-wrap">
          <div class="wine-list-mobile-header">
            <!-- TODO :: 잠시 예외 처리 -->


            <div class="flex justify-between w-full">
              <div class="bg-[center_left] pl-[32px] bg-no-repeat bg-[length_24px] text-[16px] !font-[Suit-Bold] text-darkDark leading-[32px]"
                   :style="{backgroundImage: `url('${require('@/assets/images/icon/mobile-filter.png')}')`}"
                   @click="filterModalIsOpen = true"
              >Filter</div>
              <div class="py-[6px] text-[16px] text-[#A8221A] font-[Suit-Bold]" @click="isOpenSortFilter=true">{{ getSort() }}</div>
            </div>


          
          </div>
          <div>
<!--            <div class="filter-wrap" @click="openFilterModal">
              <div class="filter-icon"></div>
              <div class="cursor-pointer" @click="filterModalIsOpen=true">Filter</div>
            </div>-->
            <div></div>
<!--              <div v-if="$route.query.sub_category_no" class="flex flex-col gap-[16px] w-full">
            <div class="w-full h-[1px] bg-light"/>
            <div class="flex gap-[10px] relative">

              <div class="flex w-full overflow-auto gap-[8px] flex-grow pr-[30px]">
                <div
                    v-for="(filter, index) in selectFilterList"
                    :key="filter.name"
                    class="flex w-fit flex-shrink-0 items-center flex-[0 0 auto] border border-lightLightDark rounded-[16px] h-[32px] pl-[16px] pr-[6px]"
                    @click="removeFilter(filter, index)"
                >
                  <p class=" text-[14px] font-[Suit-Medium] text-darkLight m-0 flex-shrink-0">{{filter.mainName}}: {{filter.name}}</p>
                  <img src="@/assets/images/icon/icon-dark-dark-close.png" class="w-[32px] h-[32px] flex-shrink-0" />
                </div>
              </div>
              <div class="cursor-pointer w-[32px] h-[32px] bg-no-repeat bg-center" @click="resetFilter" :style="{backgroundImage: `url('${require('@/assets/images/icon/filter-reload.png')}')`}"/>
              <div class="w-[40px] h-[32px] top-0 bottom-0 bg-gradient-to-r from-[#FFFFFF00] to-white absolute right-[39px]" />

            </div>-->
          </div>
          <mobile-membership-list
                  :membership-n-f-t-list="viewMembershipList"
                  :next-page="nextPage"
                  :no-result-text="noResultText"
                  >

          </mobile-membership-list>
          <FilterModal
            :isOpen="filterModalIsOpen"
            :originFilterList="filterList"
            :close-filter-modal="mobileFilterChange"
            @change="mobileFilterChange"
            @close="filterModalIsOpen=false"
            v-if="filterModalIsOpen"
          />
        </div>
      </div>
    </div>

    <NftSortModal :order="sortFilter" v-if="isOpenSortFilter" @change="changeOrder" />


  </div>
</template>
<script>
import getContractApi from '../../api/contract';
// import {
//   firstWineDummyList,
//   secondWineDummyList,
// } from '../../assets/dummy/nftFilterDummyList';
import PcGroupCheckbox from '../../components/checkbox/pcGroupCheckbox';
import FilterModal from '../../components/modals/FilterModal.vue';
import PcMembershipList from "../../components/list/PcMembershipList";
import MobileMembershipList from "../../components/list/MobileMembershipList";
import NftSortModal from "@/components/modals/NftSortModal";

export default {
  components: {NftSortModal, MobileMembershipList, PcMembershipList,   PcGroupCheckbox, FilterModal },
  created() {
    this.market = getContractApi();
    if(this.$route.query.order) {
      this.nftOrder = this.$route.query.order
    }
    this.searchData = this.$route.query
    if(this.searchData.page) {
      this.getMembershipList(this.searchData.page)
    } else {
      this.getMembershipList(1)
    }

  },
  mounted() {
    this.$store
            .dispatch('wineFilterList/getMembershipFilterList', {})
            .then((data) => {
              // console.dir(data)

              this.filterList = data;
              if(this.$route.query.sub_category_no) {
                let subCategoryList = this.$route.query.sub_category_no.split(',')
                // console.dir(subCategoryList)
                this.filterList.forEach(main => {
                  main.list.forEach(sub => {
                    if(subCategoryList.find(item => item === sub.key.toString())) {
                      sub.select = true
                      main.isOpen = true
                    }

                  })
                })
              }
            });
  },
  data() {
    return {
      nftOrder:"1",
      contract: null,
      viewMembershipList: [],
      filterList: [],
      sortFilter: this.$route.query.order ? Number(this.$route.query.order) : 1 ,
      membershipList: [],
      filterModalIsOpen: false,
      // firstMembershipList: firstMembershipDummyList,
      // secondMembershipList: secondMembershipDummyList,
      pageInfo: {
        current_page: 1,
        total_page: 1,
        item_per_page: 1,
      },
      noResultText: "NFT 리스트를 로드 중입니다. 잠시만 기다려 주십시오.",
      searchData: {},
      isOpenFilterModal: false,

      isOpenSortFilter: false,

    };
  },
  methods: {
    mobileFilterChange(data){
      // console.dir(data)
      this.filterList = data.filter;
      this.changeFilter();
    },

    changeFilter() {
      this.filterModalIsOpen = false;
      this.filterSelected = ''
      this.filterList.forEach(item => {
        item.list.forEach(listItem => {
          if(listItem.select) {
            this.filterSelected = this.filterSelected + listItem.key + ','
          }
        })
      })

      this.searchData.sub_category_no = this.filterSelected


      this.getMembershipList(1,"pass")
    },

    getMembershipFilterList() {
      this.$store
        .dispatch('wineFilterList/getMembershipFilterList', {})
        .then((data) => {
          this.filterList = data;
          this.changeFilter()
        });
    },
    openFilterModal() {
      this.filterModalIsOpen = true;
    },
    closeFilterModal() {
      this.filterModalIsOpen = false;
    },
    clickPager(page) {
      this.getMembershipList(page,"pass")
    },
    getMembershipList(page, mode, state) {
      let self = this
      // 통신 처리
      if(mode !== 'mobile') {
        if (page !== this.pageInfo.current_page) {
          // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }

      this.searchData.page = page
      this.searchData.order = this.nftOrder

      if(mode === "pass") {
        location.href='/market/membership/list?' + new URLSearchParams(this.searchData).toString()
      }

      this.$store.dispatch('membershipMarket/getMembershipList', this.searchData).then((data) => {
        // console.dir(data)
        if(data.total === 0) {
          self.noResultText = "검색 결과가 없습니다."
        }
        let searchedMembershipList = data.data
        for(let i = 0; i < searchedMembershipList.length ; i++) {
          searchedMembershipList[i].image = searchedMembershipList[i].membership_image
          searchedMembershipList[i].key = searchedMembershipList[i].market_key+"/"+searchedMembershipList[i].nft_key
          searchedMembershipList[i]['price'] = {
            coin: searchedMembershipList[i].last_price / 1000000,
            won: self.market.toWon(searchedMembershipList[i].last_price / 1000000)
          }
        }
        if(mode === 'mobile') {
          self.viewMembershipList.push(...searchedMembershipList)
            state.loaded();
        } else {
          self.viewMembershipList = searchedMembershipList
        }

        self.pageInfo = {
          current_page: data.current_page,
          total_page: data.last_page,
          item_per_page: data.per_page,
        }
        return data
      }).catch(e => {
          return e
      })

    },
    changeOrder(order) {
      this.nftOrder = order;
      this.sortFilter = order;
      this.filterModalIsOpen=false;
      this.getMembershipList(1,"pass")
    },
      nextPage($state) {
          // 통신 연동 부분
          let self = this
          if (this.pageInfo.current_page < this.pageInfo.total_page) {
              self.getMembershipList(this.pageInfo.current_page + 1, 'mobile', $state)
              // $state.loaded(); // 다음 페이지가 있을 경우

          } else {
              $state.complete(); // 다음 페이지가 없을 경우
          }
      },

    getSort(){
      // console.dir(this.sortFilter)

      switch(this.sortFilter){
        case 1:
          return "최신순";
        case 2:
          return "가격 높은 순";
        case 3:
          return "가격 낮은 순";

      }
    },

  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/nft-list.scss';
</style>
